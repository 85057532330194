import React, { useEffect, useState } from "react";

const GeolocationPopup = props => {
    const [geolocationPopup, setGeolocationPopup] = useState(false);
    const [geolocationPopupData, setGeolocationPopupData] = useState(false);
    useEffect(() => {
        const localization = window.location.pathname.slice(0, 6);
        if(localStorage.getItem("nioxin-geolocation-popup-data") !== localization) {
            getGeolocationData();
        }
        if(!localStorage.getItem("nioxin-geolocation-popup")) {
            setGeolocationPopup(true);
        }
    });
    const onPopupClose = (e) => {
        e.preventDefault();
        setGeolocationPopup(false);
        localStorage.setItem("nioxin-geolocation-popup", true);
    };
    const getGeolocationData = () => {
        const localization = window.location.pathname.slice(0, 6);
        const url = `${window.location.origin}${localization}/shared/geolocationpopup`;

        fetch(url)
            .then(response => response.json())
            .then(data => setGeolocationPopupData(data), localStorage.setItem("nioxin-geolocation-popup-data", localization))
            .catch((error) => {
                console.error('Error:', error);
            });
    }
    return (
        <React.Fragment>
            {geolocationPopupData && geolocationPopup ? (
                <div className="geolocationPopup">
                    <div className="geolocationPopup__message">
                        <h2 className="geolocationPopup__message__title">{geolocationPopupData.message}</h2>
                        <button className="geolocationPopup__message__close" onClick={onPopupClose}>×</button>
                    </div>
                    <div className="geolocationPopup__buttons">
                        <a href={geolocationPopupData.redirect_url} className="geolocationPopup__button button">{geolocationPopupData.confirm_label}</a>
                        <a href="#" className="geolocationPopup__button button" onClick={onPopupClose}>{geolocationPopupData.reject_label}</a>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
};

export default GeolocationPopup;
